@keyframes pulse {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.1);
    }
    100% {
      transform: scale(1);
    }
  }
  
  .delay-0 {
    animation: pulse 1s infinite alternate 0s;
  }
  
  .delay-1 {
    animation: pulse 1s infinite alternate 0.2s;
  }
  
  .delay-2 {
    animation: pulse 1s infinite alternate 0.4s;
  }
  